import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"
import Hero from "../../components/Hero/Hero"
import ContentType from "../../components/CustomTemplate/CustomContentType"

const AboutVindicia = ({ data }) => {
  const { uid, data: pageData } = data.prismicAboutPage

  return (
    <Layout>
      <SEO
        title={pageData.seo_title}
        description={pageData.seo_description}
        keywords={pageData.seo_keywords}
      />
      <div className="default-theme custom-template">
        <Hero page={uid} data={pageData} />
        {pageData.body.map(section => {
          return <ContentType key={`section-${section.primary.section_id}`} section={section} />
        })}
      </div>
    </Layout>
  )
}

export default withPreview(AboutVindicia)

export const query = graphql`
  {
    prismicAboutPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        color_theme
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        custom_page_title
        hero_title {
          html
          text
        }
        hero_text_alignment
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        body {
          ... on PrismicAboutPageBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicAboutPageBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              section_id
              section_name
              section_theme
              section_background_color
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicAboutPageBodyFullWidthBanner {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              cta_url {
                url
                target
              }
              cta_text
              theme_color_section
            }
            slice_type
          }
          ... on PrismicAboutPageBodyFullWidthBannerWithVideoOverlay {
            primary {
              section_id
              section_name
              copy {
                html
              }
              video_id
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicAboutPageBody2ColumnWithAccordion {
            primary {
              section_id
              section_name
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              accordion_element_body {
                html
              }
              accordion_element_title
            }
          }
          ... on PrismicAboutPageBody3ColumnWithIcon {
            primary {
              section_id
              section_name
            }
            items {
              icon_image {
                fixed(height: 50) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              short_copy {
                html
              }
              cta_link_url {
                target
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicAboutPageBody1ColumnContainerSmall {
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicAboutPageBody3ColumnGrid {
            primary {
              section_id
              section_name
              section_title {
                text
              }
            }
            items {
              sub_mgmt_items {
                uid
                document {
                  ... on PrismicSubscriptionManagement {
                    data {
                      short_copy {
                        html
                      }
                      short_title
                      icon_image {
                        fixed(height: 60) {
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                      url_prefix
                      second_url_prefix
                    }
                  }
                }
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicAboutPageBody3ColumnCards {
            primary {
              section_id
              section_name
              section_title_centered {
                text
              }
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicAboutPageBodyScrollerWithBg {
            id
            slice_type
            primary {
              section_name
              section_id
              background_image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              slide_title
              slide_copy {
                html
              }
              slide_image_desktop {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              slide_image_mobile {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicAboutPageBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicAboutPageBodyTestimonials {
            primary {
              section_id
              section_name
              testimonials_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              case_study
              copy
              source
              logo {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicAboutPageBodyPromotionComponent {
            primary {
              section_id
              section_name
              section_promo_title
              section_description {
                html
              }
            }
            slice_type
            items {
              item_title
              item_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              item_image {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicAboutPageBodyImageGallery {
            primary {
              section_id
              section_name
              section_description {
                html
              }
            }
            slice_type
            items {
              item_description {
                html
              }
              item_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicAboutPageBodyNumberFact {
            primary {
              section_id
              section_name
              section_title {
                html
              }
              section_description {
                html
              }
            }
            slice_type
            items {
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
            }
          }
          ... on PrismicAboutPageBodyRelatedMaterialComponent {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
              }
              item_doc_title {
                html
              }
              link_item {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicAboutPageBodyHoverBackgroundZoomBlock {
            primary {
              section_id
              section_name
            }
            slice_type
            items {
              heading {
                html
              }
              description {
                html
              }
              button_link {
                target
                url
              }
              button_link_text
              background {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              overlay_color
            }
          }
        }
      }
    }
  }
`
